
<template>
  <div class="hero">
    <!-- <div class="hero-content">
      <h1>AI模特换装领航者</h1>
      <p>AI模特换装平台，可以帮助服装商家去模特拍摄的成本，一键生成高颜值AI模特照，极大的降低服装商的成本支出以及提高商品的转化利润</p>
    </div>
    <img src="@/assets/hero-image.jpg" alt="Hero Image" class="hero-image"> -->
    <div class="herobg">
      <img src="https://huanyingyichu.oss-cn-shanghai.aliyuncs.com/60811725440574_.pic_hd.jpg" alt="Hero Image" class="hero-image">
      <el-button class="trybtn" type="primary" @click="handleTry">立即体验</el-button>
    </div>
    <!-- <div class="herobg">
      <el-image class="bgimg" :src="bgImg" />
      <el-image class="bgrwimg" :src="bgRw" />
      <div class="title">
        <span>让您的商品脱颖而出</span>
      </div>
      <div class="content">
        <div>告别昂贵模特，海量虚拟模特</div>
        <div>随手拍的图也能生成商品营销图</div>
        <div>1分钟生图，快人一步</div>
      </div>
      <el-button class="trybtn" @click="handleTry">立即试试</el-button>
      <el-image class="right1" :src="bgRight1" />
      <el-image class="right2" :src="bgRight2" />
    </div> -->
    <div class="bgjiaohu">
      <div style="margin-right: 50px">
        <div class="aisc">AI智能生成</div>
        <div>一起来试试吧!</div>
      </div>
      <div style="display: flex">
        <div
          class="btnItem"
          :class="{ 'hovered': isHovered1 }" 
          @mouseenter="isHovered1 = true" 
          @mouseleave="isHovered1 = false"
          @click="handleTemp('1')"
        >
          <div class="icondiv">
            <el-image class="icon" :src="isHovered1 ? diaodai2 : diaodai1" />
            <div>AI换装</div>
          </div>
        </div>

        <div
          class="btnItem"
          :class="{ 'hovered': isHovered2 }" 
          @mouseenter="isHovered2 = true" 
          @mouseleave="isHovered2 = false"
          @click="handleTemp('3')"
        >
          <div class="icondiv">
            <el-image class="icon" :src="isHovered2 ? banlv2 : banlv1" />
            <div>真人图</div>
          </div>
        </div>

        <div
          class="btnItem"
          :class="{ 'hovered': isHovered3 }" 
          @mouseenter="isHovered3 = true" 
          @mouseleave="isHovered3 = false"
          @click="handleTemp('2')"
        >
          <div class="icondiv">
            <el-image class="icon" :src="isHovered3 ? rentai2 : rentai1" />
            <div>人台图</div>
          </div>
        </div>

        <div
          class="btnItem"
          :class="{ 'hovered': isHovered4 }" 
          @mouseenter="isHovered4 = true" 
          @mouseleave="isHovered4 = false"
          @click="handleTemp('4')"
        >
          <div class="icondiv">
            <el-image class="icon" :src="isHovered4 ? bao2 : bao1" />
            <div>商品图</div>
          </div>
        </div>

        <div
          class="btnItem"
          :class="{ 'hovered': isHovered5 }" 
          @mouseenter="isHovered5 = true" 
          @mouseleave="isHovered5 = false"
          @click="handleTemp('6')"
        >
          <div class="icondiv">
            <el-image class="icon" :src="isHovered5 ? xie2 : xie1" />
            <div>鞋子上脚</div>
          </div>
        </div>

        <div
          class="btnItem"
          :class="{ 'hovered': isHovered6 }" 
          @mouseenter="isHovered6 = true" 
          @mouseleave="isHovered6 = false"
          @click="handleTemp('5')"
        >
          <div class="icondiv">
            <el-image class="icon" :src="isHovered6 ? mote_2 : mote_1" />
            <div>模特训练</div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import bgimg from '@/assets/bg.png';
import bgrenwu from '@/assets/bgrenwu.png';
import changjing1 from '@/assets/changjing6.jpg';
import changjing2 from '@/assets/changjing4.jpg';
import diaodai1 from '@/assets/diaodai1.png';
import diaodai2 from '@/assets/diaodai2.png';
import bao1 from '@/assets/bao1.png';
import bao2 from '@/assets/bao2.png';
import banlv1 from '@/assets/banlv1.png';
import banlv2 from '@/assets/banlv2.png';
import rentai1 from '@/assets/rentai1.png';
import rentai2 from '@/assets/rentai2.png';
import xie1 from '@/assets/xie1.png';
import xie2 from '@/assets/xie2.png';
import mote_1 from '@/assets/mote_1.png';
import mote_2 from '@/assets/mote_2.png';
import { eventBus } from '@/main';

export default {
  name: 'Hero',
  data() {
    return {
      bgImg: bgimg,
      bgRw: bgrenwu,
      bgRight1: changjing1,
      bgRight2: changjing2,
      diaodai1: diaodai1,
      diaodai2: diaodai2,
      bao1: bao1,
      bao2: bao2,
      banlv1: banlv1,
      banlv2: banlv2,
      rentai1: rentai1,
      rentai2: rentai2,
      xie1: xie1,
      xie2: xie2,
      mote_1: mote_1,
      mote_2: mote_2,
      isHovered1: false,
      isHovered2: false,
      isHovered3: false,
      isHovered4: false,
      isHovered5: false,
      isHovered6: false,
    }
  },
  created() {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    document.onkeydown = (e) => {
      if (e.key === 'F12' || (e.ctrlKey && e.shiftKey && e.key === 'I')) {
        e.preventDefault();
      }
    };
  },
  mounted() {},
  methods: {
    handleTry() {
      eventBus.$emit('goAiHuanzhuang', 'AIModelLibrary');
    },
    handleTemp(val) {
      eventBus.$emit('goAiModel', 'AIModelLibrary', val);
    }
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'youshe';
  src: url('../assets/fonts/YouSheBiaoTiHei-2.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.hero {
  width: 100%;
  height: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 80px; */
}
.bgjiaohu {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aisc {
  font-family: 'youshe', sans-serif;
  font-size: 30px;
}
.btnItem {
  height: 120px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  box-sizing: border-box;
  border: 3px solid #BB70D1;
  border-radius: 10px;
  /* border-image: linear-gradient(0deg, #3E93F0, #A835F2) 3 3; */
}
.hovered {
  border: none;
  background: linear-gradient(19deg, #3E93F0, #A835F2);
  color: #fff;
  cursor: pointer;
}
.icondiv {
  text-align: center;
}
.icon {
  height: 40px;
  width: 40px;
}
.icon /deep/ .el-image__inner {
  height: 100%;
  width: auto;
}
.herobg {
  /* height: 704px; */
  width: 100%;
  position: relative;
}
.bgimg {
  /* height: 704px; */
  width: 100%;
  position: absolute;
  display: block;
}
.bgimg /deep/.el-image__inner {
  width: auto;
  height: 100%;
}
.bgrwimg {
  height: 95%;
  width: auto;
  position: absolute;
  top: 33px;
  right: 250px;
  z-index: 2;
}
.right1 {
  position: absolute;
  height: 150px;
  width: 100px;
  right: 210px;
  top: 102px;
  border-radius: 10px;
  border: 10px solid rgba(255,255,255,0.25);
  box-shadow: 0px 0px 46px 11px rgba(67,142,240,0.16);
}
.right1 /deep/ .el-image__inner {
  height: 100%;
  width: auto;
}
.right2 {
  position: absolute;
  height: 120px;
  width: 90px;
  right: 100px;
  top: 115px;
  border-radius: 10px;
  border: 10px solid rgba(255,255,255,0.25);
  box-shadow: 0px 0px 46px 11px rgba(67,142,240,0.16);
}
.right2 /deep/ .el-image__inner {
  height: auto;
  width: 100%;
}
.title {
  position: absolute;
  top: 166px;
  left: 100px;
  font-family: 'youshe', sans-serif;
  color: #000;
  font-size: 60px;
}
.content {
  position: absolute;
  top: 306px;
  left: 115px;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
}
.trybtn {
  width: 200px;
  height: 60px;
  font-size: 20px;
  position: absolute;
  border-radius: 36px;
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  color: #fff;
  left: 10%;
  bottom: 30%;
}
.trybtn:hover {
  background: linear-gradient(0deg, #A835F2, #3E93F0);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.2) inset !important;
}
.hero-content {
  max-width: 600px;
}
.hero-image {
  /* height: 300px; */
  height: auto;
  width: 100%;
}
</style>
    